import Global from '~icons/icons/GlobalLogo.svg'
import GiorgioArmani from '~icons/icons/GiorgioArmaniLogo.svg'
import GaPoldo from '~icons/icons/GaPoldoLogo.svg'
import GaNeve from '~icons/icons/GaNeveLogo.svg'
import ThatsSoArmani from '~icons/icons/ThatsSoArmaniLogo.svg'
import GinzaTower from '~icons/icons/GinzaTowerLogo.svg'
import EmporioArmani from '~icons/icons/EmporioArmaniLogo.svg'
import Ea7 from '~icons/icons/Ea7Logo.svg'
import ArmaniExchange from '~icons/icons/ArmaniExchangeLogo.svg'
import ArmaniCasa from '~icons/icons/ArmaniCasaLogo.svg'
import ArmaniFiori from '~icons/icons/ArmaniFioriLogo.svg'
import ArmaniDolci from '~icons/icons/ArmaniDolciLogo.svg'
import ArmaniBeauty from '~icons/icons/ArmaniBeautyLogo.svg'
import ArmaniRestaurant from '~icons/icons/ArmaniRestaurantLogo.svg'
import ArmaniHotelsResorts from '~icons/icons/ArmaniHotelsResortsLogo.svg'
import ArmaniSilos from '~icons/icons/ArmaniSilosLogo.svg'
import ArmaniClubs from '~icons/icons/ArmaniClubsLogo.svg'
import EchiDalMondo from '~icons/icons/EchiDalMondoLogo.svg'

export type LogoType = keyof typeof logoMap

export const logoMap = {
  global: Global,
  'giorgio-armani': GiorgioArmani,
  'ga-poldo': GaPoldo,
  'ga-neve': GaNeve,
  'thats-so-armani': ThatsSoArmani,
  'ginza-tower': GinzaTower,
  'emporio-armani': EmporioArmani,
  ea7: Ea7,
  'armani-exchange': ArmaniExchange,
  'armani-casa': ArmaniCasa,
  'armani-fiori': ArmaniFiori,
  'armani-dolci': ArmaniDolci,
  'armani-beauty': ArmaniBeauty,
  'armani-restaurant': ArmaniRestaurant,
  'armani-hotels-resorts': ArmaniHotelsResorts,
  'armani-silos': ArmaniSilos,
  'armani-clubs': ArmaniClubs,
  'echi-dal-mondo': EchiDalMondo,
}
